<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-plus" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Map Programmes to Institutions
                    </div>
                    <div class="text-sm text-500">
                      Create institution programmes
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div>
                    <table class="p-datatable-table" style="width: 100%">
                      <tbody>
                        <tr
                          v-for="(formData, row) in arrFormData"
                          :key="row"
                          class="input-row"
                        >
                          <div class="mx-2">
                            <div class="grid">
                              <div class="col-12 md:col-6">
                                <div class="mb-2 font-bold text-sm">
                                  Institution *
                                </div>

                                <div class="">
                                  <api-data-source
                                    @loaded="
                                      (response) =>
                                        mapOptionField(
                                          response,
                                          'caps_institution_id'
                                        )
                                    "
                                    api-path="components_data/caps_institution_id_option_list"
                                  >
                                    <template v-slot="req">
                                      <Dropdown
                                        class="w-full"
                                        :class="
                                          getErrorClass(
                                            row,
                                            'caps_institution_id'
                                          )
                                        "
                                        :loading="req.loading"
                                        optionLabel="label"
                                        optionValue="value"
                                        ref="ctrlcaps_institution_id"
                                        v-model="formData.caps_institution_id"
                                        :options="req.response"
                                        label="Institution"
                                        placeholder="Select ..."
                                        :filter="true"
                                      >
                                        <template #option="slotProps">
                                          <div class="flex align-items-center">
                                            <div
                                              class="ml-2"
                                              v-if="slotProps.option.image"
                                            >
                                              <Avatar
                                                :image="
                                                  $utils.setImgUrl(
                                                    slotProps.option.image
                                                  )
                                                "
                                              />
                                            </div>
                                            <div>
                                              <div>
                                                {{ slotProps.option.label }}
                                              </div>
                                              <div
                                                v-if="slotProps.option.caption"
                                                class="text-sm text-500"
                                              >
                                                {{ slotProps.option.caption }}
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </Dropdown>
                                    </template>
                                  </api-data-source>
                                </div>
                              </div>
                              <div class="col-12 md:col-6">
                                <div class="mb-2 font-bold text-sm">
                                  Programme *
                                </div>

                                <div class="">
                                  <api-data-source
                                    @loaded="
                                      (response) =>
                                        mapOptionField(
                                          response,
                                          'caps_programme_id'
                                        )
                                    "
                                    api-path="components_data/caps_programme_id_option_list"
                                  >
                                    <template v-slot="req">
                                      <Dropdown
                                        class="w-full"
                                        :class="
                                          getErrorClass(
                                            row,
                                            'caps_programme_id'
                                          )
                                        "
                                        :loading="req.loading"
                                        optionLabel="label"
                                        optionValue="value"
                                        ref="ctrlcaps_programme_id"
                                        v-model="formData.caps_programme_id"
                                        :options="req.response"
                                        label="Programme"
                                        placeholder="Select ..."
                                        :filter="true"
                                      >
                                        <template #option="slotProps">
                                          <div class="flex align-items-center">
                                            <div
                                              class="ml-2"
                                              v-if="slotProps.option.image"
                                            >
                                              <Avatar
                                                :image="
                                                  $utils.setImgUrl(
                                                    slotProps.option.image
                                                  )
                                                "
                                              />
                                            </div>
                                            <div>
                                              <div>
                                                {{ slotProps.option.label }}
                                              </div>
                                              <div
                                                v-if="slotProps.option.caption"
                                                class="text-sm text-500"
                                              >
                                                {{ slotProps.option.caption }}
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </Dropdown>
                                    </template>
                                  </api-data-source>
                                </div>
                              </div>
                            </div>
                          </div>
                          <th>
                            <Button
                              class="p-button-text p-button-danger p-button-rounded p-button-sm"
                              v-if="arrFormData.length > minRowCount"
                              @click="removeFormRow(row)"
                              icon="pi pi-times"
                            />
                          </th>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="100" class="text-right">
                            <Button
                              v-if="arrFormData.length < maxRowCount"
                              @click="addFormRow()"
                              icon="pi pi-plus"
                              class="p-button-text p-button-success p-button-rounded p-button-sm"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div v-if="showSubmitButton" class="text-center q-my-md">
                    <Button
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addCapsinstitutionprogrammesPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_institution_programmes",
    },
    routeName: {
      type: String,
      default: "caps_institution_programmesadd",
    },
    apiPath: {
      type: String,
      default: "caps_institution_programmes/add",
    },
    maxRowCount: {
      type: Number,
      default: 20,
    },
    minRowCount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      arrFormData: [
        {
          caps_institution_id: "",
          caps_programme_id: "",
        },
      ],
    };
  },
  validations() {
    let arrFormData = {
      $each: helpers.forEach({
        caps_institution_id: { required, numeric },
        caps_programme_id: { required, numeric },
      }),
    };
    return { arrFormData };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Institution Programme";
      },
    },
  },
  methods: {
    ...mapActions("caps_institution_programmes", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        let errors = this.v$.arrFormData.$each.$response.$errors;
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.saving = false;
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          location.reload();
         // if (this.redirect) this.navigateTo(`/caps_institution_programmes`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    addFormRow() {
      let newFormData = { caps_institution_id: "", caps_programme_id: "" };
      this.arrFormData.push(newFormData);
    },
    removeFormRow(index) {
      this.arrFormData.splice(index, 1);
    },
    resetForm() {
      this.arrFormData = [{ caps_institution_id: "", caps_programme_id: "" }];
      this.updateFormData();
    },
    getErrorClass(row, field) {
      let errors = this.v$.arrFormData.$each.$response.$errors[row][field];
      return { "p-invalid": errors.length && this.submitted };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
